@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: #ececec;
  }
}

.app {
  position: absolute;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100%;
  font-family: "Noto Sans HK", sans-serif;
}
